<template>
  <div class="container" style="padding-top: 50px; ">
    <button @click="title = 'Changed Popup Title'">Change Title</button>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group field-ticketform-longitude">
          <label class="control-label" data-custom-for="ticketform-longitude">Longitude</label>
          <input type="text" data-custom-id="ticketform-longitude" class="form-control" v-model="lon" maxlength="">
          <p class="help-block help-block-error"></p>
        </div>
      </div>
      <div class="col-md-2"> <div class="form-group field-ticketform-latitude">
        <label class="control-label" data-custom-for="ticketform-latitude">Latitude</label>
        <input type="text" data-custom-id="ticketform-latitude" class="form-control" v-model="lat" maxlength="">

        <p class="help-block help-block-error"></p>
      </div></div>

      <div class="col-md-7">
        <div class="form-group field-ticketform-address">
          <label class="control-label" data-custom-for="ticketform-address">Address</label>
          <div class="input-group">
            <a class="input-group-text" @click="searchAdressOnMap">
              <i class="fas fa-map-marker-alt fa-lg" aria-hidden="true"></i>
            </a>
            <input type="text" data-custom-id="ticketform-address" class="form-control" v-model="addressFromMap" maxlength="">
          </div>
          <p class="help-block help-block-error"></p>
        </div>
      </div>
      <div class="col-md-1">
        <div class="form-group field-ticketform-longitude">
          <label class="control-label" data-custom-for="ticketform-longitude">Id</label>
          <input type="text" data-custom-id="ticketform-longitude" class="form-control" v-model="choosedApartmentId" maxlength="">
          <p class="help-block help-block-error"></p>
        </div>
      </div>
    </div>
    <div id="map" />
    <div id="mapContainer" />

  </div>

</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {computed, onMounted} from "vue";
import { createApp, defineComponent, ref, nextTick } from "vue";
import MapContent from "@/components/MapContent.vue";
import axios from "axios";
import {useStore} from "@/store";
import {ActionTypes} from "@/store/actions";
// import {red} from "colorette";
// import { LMap, LGeoJson } from "./../../components";

export default {
  setup() {
    const lat = ref(45.3148);
    const lon = ref(14.2873);
    // const setupLeafletMap =   () => {
    //   const mapDiv = L.map("mapContainer").setView([lon,lat], 13);
    //   L.tileLayer(
    //       "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
    //       {
    //         attribution:
    //             'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
    //             '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) ' +
    //             '<a href="https://www.mapbox.com/">Mapbox</a>',
    //         maxZoom: 18,
    //         id: "mapbox/streets-v11",
    //         accessToken:"pk.eyJ1IjoiaG9zdGEiLCJhIjoiY2pzYzAzYTVzMGc2cDQzbXV0YnVvdms2NSJ9.8_IUy4eQ7Ctcl3ImkHvmZw",
    //       }
    //   ).addTo(mapDiv);
    // }
    const title = ref("Unchanged Popup Title");
    const store = useStore();
    const apartments = computed(() => store.getters.apartments);
    const choosedApartmentId = ref(-1);

    const addressFromMap = ref("");
    const currentDay = computed( () => store.getters.currentDay);
    const resources = computed(() => store.getters.filteredResources);

    const  fetchAddressWithMarker = (longitude,latitude) => {
      const url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latitude + "," + longitude + "&key=AIzaSyCuVxfF41Hk2T6mwb2eR8V42PBUV7JdWL4";
      console.log(url)

      axios.get(url).then((response) => {

        if (response.status === 200) {
          lat.value = response.data.results[0].geometry.location.lat;
          lon.value = response.data.results[0].geometry.location.lng;
          addressFromMap.value = response.data.results[0].formatted_address;
        }
      });
    };
    const onDragEnd = (el) => {
      lat.value = el.target.getLngLat().lat;
      lon.value = el.target.getLngLat().lng;
      fetchAddressWithMarker(lon.value,lat.value);
    };
    const onDragStart = (el) => {
      lat.value = el.target.getLngLat().lat;
      lon.value = el.target.getLngLat().lng;
    };

    const onMouseMoving = (el) => {
      lat.value = el.lngLat.lat;
      lon.value = el.lngLat.lng;
    };

    const searchAdressOnMap = () => {

      const url = "https://maps.googleapis.com/maps/api/geocode/json?address="+ addressFromMap.value + "&key=AIzaSyCuVxfF41Hk2T6mwb2eR8V42PBUV7JdWL4";
      axios.get(url).then((response) => {
        if(response.status===200) {
          lat.value = response.data.results[0].geometry.location.lat;
          lon.value = response.data.results[0].geometry.location.lng;
          addressFromMap.value = response.data.results[0].formatted_address;
        }
      });
    };
    const chooseObject = (id) => {

      const apartment = apartments.value.filter((apartment)=> apartment.id === id)
      lat.value = apartment.latitude;
      lon.value = apartment.longitude;
      addressFromMap.value = apartment.address;
    }

    onMounted(() => {
      // setupLeafletMap();

      store.dispatch(ActionTypes.fetchApartments,null);
      store.dispatch(ActionTypes.fetchResources,(currentDay.value/ 1000).toFixed(0));
      mapboxgl.accessToken ='pk.eyJ1IjoiaG9zdGEiLCJhIjoiY2pzYzAzYTVzMGc2cDQzbXV0YnVvdms2NSJ9.8_IUy4eQ7Ctcl3ImkHvmZw';
      const map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [lon.value,lat.value],
        zoom: 8
      });
      map.on("load", () => {

        // map.on('click', function(e) {
        //   console.log('A click event has occurred at ' + e.lngLat);
        //   const marker = new mapboxgl.Marker({draggable: true})
        //       .setLngLat(e.lngLat)
        //       .setPopup(new mapboxgl.Popup({ offset: 25 })
        //           .setHTML('<div>New Object For Ticketing?</div>'))
        //       .addTo(map);
        //   marker.on('dragstart', onDragStart);
        //   marker.on('dragend', onDragEnd);
        // });
        map.on("click", function (e) {
          if(e.button >= 2) {
            const popup = new mapboxgl.Popup({maxWidth: 450})
                .setLngLat(e.lngLat)
                .setHTML('<div id="popup-content"></div>');
            popup.addTo(map);
            const MyNewPopup = defineComponent({
              extends: MapContent,
              setup() {
                const store = useStore();
                const idApp = ref(null);
                const cityName = ref("");
                const address = ref(addressFromMap);
                const userId = ref(null);
                const name = ref("Object ");
                const externalApartmentId = ref("");
                const latitudeApp = ref(lat);
                const longitudeApp = ref(lon);
                const priceGeneralCleaning = ref(0);
                const costGeneralCleaning = ref(0);
                const timeGeneralCleaning = ref(0);
                const priceTouchUpCleaning = ref(0);
                const costTouchUpCleaning = ref(0);
                const timeTouchUpCleaning = ref(0);
                const timeCleaning = ref(0);
                const priceCleaning = ref(0);
                const costCleaning = ref(0);
                const costOneLinen = ref(0);
                const priceOneLinen = ref(0);
                const space = ref(0);
                const addNewObject = () => {
                  console.log("done");
                  store.dispatch(ActionTypes.addObject, {
                    id: idApp.value,
                    cityName: cityName.value,
                    address: address.value,
                    userId: userId.value,
                    name: name.value,
                    externalApartmentId: externalApartmentId.value,
                    latitude: latitudeApp.value,
                    longitude: longitudeApp.value,
                    priceGeneralCleaning:
                    priceGeneralCleaning.value,
                    costGeneralCleaning: costGeneralCleaning.value,
                    timeGeneralCleaning: timeGeneralCleaning.value,
                    priceTouchUpCleaning: priceTouchUpCleaning.value,
                    costTouchUpCleaning: costTouchUpCleaning.value,
                    timeTouchUpCleaning: timeTouchUpCleaning.value,
                    timeCleaning: timeCleaning.value,
                    priceCleaning: priceCleaning.value,
                    costCleaning: costCleaning.value,
                    costOneLinen: costOneLinen.value,
                    priceOneLinen: priceOneLinen.value,
                    space: space.value,
                  });
                }
                return {
                  addNewObject,
                  idApp,
                  cityName,
                  address,
                  userId,
                  name,
                  externalApartmentId,
                  latitudeApp,
                  longitudeApp,
                  priceGeneralCleaning,
                  costGeneralCleaning,
                  timeGeneralCleaning,
                  priceTouchUpCleaning,
                  costTouchUpCleaning,
                  timeTouchUpCleaning,
                  timeCleaning,
                  priceCleaning,
                  costCleaning,
                  costOneLinen,
                  priceOneLinen,
                  space,
                };
              },
            });
            nextTick(() => {
              createApp(MyNewPopup).mount("#popup-content");
            });
            const marker = new mapboxgl.Marker({draggable: false})
                .setLngLat(e.lngLat).setHTML('<div id="' + 1 + '">' + 2 + '<br><hr>' + 3 + '</div>')
                // .setPopup(popup)
                .addTo(map);
          }
        });
        map.on('mousemove', onMouseMoving);

        const json = {
          "type": "geojson",
          "data": {
            "type": "FeatureCollection",
            "features": [
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 12:56:11",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2991,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:09:54",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2996,
                    45.3291
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:10:44",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3002,
                    45.3292
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:11:33",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3005,
                    45.3294
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:12:22",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3012,
                    45.3299
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:13:11",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3019,
                    45.3305
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:14:00",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3022,
                    45.3308
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:14:16",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3023,
                    45.3311
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:15:06",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3023,
                    45.3316
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:15:55",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3024,
                    45.3323
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:16:45",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3022,
                    45.3329
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:55:22",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3018,
                    45.3327
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:56:12",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3016,
                    45.3323
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:57:01",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3013,
                    45.3317
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:57:51",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.301,
                    45.331
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:58:39",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3008,
                    45.3307
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 13:59:29",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3003,
                    45.3302
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 14:00:27",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3006,
                    45.3298
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 14:01:16",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3006,
                    45.3296
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 14:02:05",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3001,
                    45.3292
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 14:03:50",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:23:45",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2996,
                    45.3287
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:23:45",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2988,
                    45.3291
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:23:45",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.299,
                    45.3291
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:23:45",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2996,
                    45.3284
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:23:45",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2992,
                    45.3286
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:23:45",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2991,
                    45.3287
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:23:45",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3291
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:23:45",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2989,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:50:29",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2993,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:23:45",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2991,
                    45.3288
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:53:41",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2987,
                    45.3279
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:54:30",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3276
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:55:19",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2996,
                    45.3273
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:56:09",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2993,
                    45.3266
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:56:58",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2991,
                    45.3258
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:58:03",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2993,
                    45.325
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 15:59:41",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2989,
                    45.3235
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:00:30",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2984,
                    45.3249
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:01:19",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.298,
                    45.3266
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:02:08",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2999,
                    45.333
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:03:13",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3048,
                    45.3372
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:04:02",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3074,
                    45.3409
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:04:51",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3118,
                    45.3442
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:05:40",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3172,
                    45.3498
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:06:29",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3188,
                    45.3529
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:07:18",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3237,
                    45.3567
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:08:08",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3291,
                    45.358
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:08:39",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3323,
                    45.3579
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:09:28",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3378,
                    45.3547
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:10:17",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3484,
                    45.3491
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:11:06",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3538,
                    45.3461
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:11:55",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3649,
                    45.3427
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:12:45",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3753,
                    45.341
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:13:34",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3797,
                    45.34
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:13:47",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3842,
                    45.3394
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:14:37",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3888,
                    45.3398
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:15:26",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3997,
                    45.3387
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:16:15",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4068,
                    45.3381
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:17:05",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4087,
                    45.338
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:17:54",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4148,
                    45.3369
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:18:44",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4176,
                    45.3357
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:19:33",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4248,
                    45.3321
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:19:58",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4255,
                    45.3317
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:20:49",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.43,
                    45.3306
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:21:38",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4322,
                    45.3303
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:22:27",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4389,
                    45.327
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:23:16",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4418,
                    45.3258
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:24:06",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4466,
                    45.3261
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:24:55",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4487,
                    45.3264
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:25:59",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.449,
                    45.3247
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:26:48",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4514,
                    45.3236
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:29:03",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4699,
                    45.3172
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:29:53",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4677,
                    45.3184
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:30:18",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4663,
                    45.3189
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:31:07",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4658,
                    45.3191
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:32:46",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4651,
                    45.3194
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:33:35",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4648,
                    45.3197
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-10 16:34:34",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4649,
                    45.32
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:26:53",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4653,
                    45.3197
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:36:39",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4647,
                    45.3197
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:36:39",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.465,
                    45.3194
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:36:41",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4658,
                    45.319
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:37:30",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4662,
                    45.3186
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:38:19",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.467,
                    45.3186
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:39:58",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4709,
                    45.3174
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:40:47",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4711,
                    45.3208
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:41:36",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4731,
                    45.321
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:41:50",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4764,
                    45.3204
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:42:40",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4768,
                    45.3222
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:43:29",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4822,
                    45.3189
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:44:18",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4923,
                    45.3223
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:45:07",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4884,
                    45.3249
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:45:56",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4748,
                    45.3319
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:46:58",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4637,
                    45.3355
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:48:19",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4425,
                    45.3369
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:49:09",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4345,
                    45.3387
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:50:32",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4105,
                    45.3432
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:51:21",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3906,
                    45.347
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:52:03",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.382,
                    45.3479
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:52:52",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3608,
                    45.3511
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:53:41",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3517,
                    45.3554
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:54:30",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3372,
                    45.3614
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:55:19",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3344,
                    45.3628
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:56:08",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3272,
                    45.361
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:56:57",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3253,
                    45.3605
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:57:59",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3273,
                    45.3608
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:58:48",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3285,
                    45.3587
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 07:59:37",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3236,
                    45.3568
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:00:25",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3213,
                    45.3556
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:01:15",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3179,
                    45.3522
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:02:05",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3139,
                    45.3457
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:03:09",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.308,
                    45.3421
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:03:58",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3069,
                    45.34
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:04:47",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3022,
                    45.3355
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:05:35",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3027,
                    45.3321
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:06:25",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3014,
                    45.3299
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:07:14",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2993,
                    45.3253
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:08:04",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2991,
                    45.3244
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:08:36",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2993,
                    45.3248
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:09:25",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2993,
                    45.3251
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:10:14",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.299,
                    45.3259
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:11:04",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2991,
                    45.3262
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:11:53",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.327
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:12:42",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2991,
                    45.3277
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:13:31",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2985,
                    45.3279
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:14:30",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2987,
                    45.3284
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:38:23",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2998,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:15:23",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2992,
                    45.3288
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:39:10",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3291
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:39:58",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2992,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 08:40:50",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2997,
                    45.3285
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 09:07:49",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3283
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 09:07:49",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2989,
                    45.329
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 09:07:49",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2989,
                    45.3285
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 09:09:01",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2996,
                    45.3291
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2020-09-11 09:41:03",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2991,
                    45.3291
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-04 11:25:37",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4567,
                    45.5677
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-04 12:10:23",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4567,
                    45.5677
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-06 20:28:12",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4567,
                    45.5677
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-07 19:31:18",
                  "user_id": 374,
                  "device": "eDNxTWseHUSomnzILvUMHb:APA91bGr99D8bluf8Nd3cjr3pJnDFGmlaCafi2DH7BkSeeADehBI-oa-elJ4sO4F7-H_-L15t3PGyMIk9B3xS-s70IhqOmDjO6w3X-Y9tQnXkwkmwbWhjBg1YO4A_oTPEbdOdhEe74wl"
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4567,
                    45.5677
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-07 19:32:54",
                  "user_id": 374,
                  "device": "eDNxTWseHUSomnzILvUMHb:APA91bGr99D8bluf8Nd3cjr3pJnDFGmlaCafi2DH7BkSeeADehBI-oa-elJ4sO4F7-H_-L15t3PGyMIk9B3xS-s70IhqOmDjO6w3X-Y9tQnXkwkmwbWhjBg1YO4A_oTPEbdOdhEe74wl"
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4675,
                    45.5888
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-07 19:40:29",
                  "user_id": 374,
                  "device": "eDNxTWseHUSomnzILvUMHb:APA91bGr99D8bluf8Nd3cjr3pJnDFGmlaCafi2DH7BkSeeADehBI-oa-elJ4sO4F7-H_-L15t3PGyMIk9B3xS-s70IhqOmDjO6w3X-Y9tQnXkwkmwbWhjBg1YO4A_oTPEbdOdhEe74wl"
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4675,
                    45.5888
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-07 20:03:18",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4675,
                    45.5888
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-08 21:39:30",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-08 21:40:10",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-08 21:42:52",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-08 21:42:59",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-08 21:42:59",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-08 21:42:59",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-08 21:43:06",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-08 21:43:28",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-08 21:58:14",
                  "user_id": 374,
                  "device": null
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3217
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-09 14:48:06",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3815,
                    45.3398
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:39",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2966,
                    45.3257
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:41",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:41",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:43",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:43",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:43",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:47",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:48",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:48",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:51",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:52",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:52",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:56",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:57",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:00:57",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-10 21:01:11",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-11 17:52:45",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4675,
                    45.5888
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 10:31:09",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 10:31:10",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 10:31:23",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 10:32:07",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 10:32:10",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 10:32:12",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 10:41:38",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2993,
                    45.3288
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 10:47:49",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 10:47:52",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 10:48:10",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:18:15",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:18:20",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:18:32",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:18:44",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:19:25",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3288
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:20:00",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3288
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:21:18",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3288
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:22:13",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:22:31",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:23:23",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:29:58",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:42:07",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:42:07",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:42:30",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3288
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:42:41",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3288
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:48:04",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:48:55",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:49:12",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:49:52",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:49:59",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:50:01",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:50:03",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:50:06",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:50:15",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:52:33",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:52:53",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:52:54",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:52:54",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:52:59",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:52:59",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:52:59",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:00",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:01",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:01",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:02",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:02",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:02",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:03",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:03",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:03",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:04",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:05",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:05",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:07",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:13",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:13",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:23",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:27",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:30",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 11:53:45",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 12:02:45",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 18:43:35",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 18:43:58",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 18:44:48",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2966,
                    45.3257
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 18:45:07",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2965,
                    45.326
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 18:45:54",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2964,
                    45.3264
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 18:46:33",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2963,
                    45.3265
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 18:46:37",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3277,
                    45.3431
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 18:46:44",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 18:47:27",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 18:47:29",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 18:47:35",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 19:14:37",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2966,
                    45.3257
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 19:14:44",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2966,
                    45.3257
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 19:14:48",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2966,
                    45.3257
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 19:14:56",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2966,
                    45.3257
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 19:15:02",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-12 19:15:18",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-13 20:12:08",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-13 11:09:48",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4567,
                    45.5677
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-13 20:12:08",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3069,
                    45.3483
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-13 20:12:08",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-13 20:17:09",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2966,
                    45.3217
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-14 11:04:55",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2973,
                    45.3262
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-14 13:38:06",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-14 13:38:18",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-14 13:38:18",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-15 16:32:40",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.4675,
                    45.5888
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-16 19:32:31",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2982,
                    45.3264
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-16 19:49:16",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3149,
                    45.3378
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-16 19:54:15",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-17 14:57:00",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-17 16:00:56",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.3533,
                    45.3554
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 08:15:59",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 08:16:03",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 08:16:14",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 12:04:28",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 12:04:29",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 12:04:29",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 12:10:08",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 12:32:10",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 12:32:35",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 12:32:37",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 12:32:52",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 12:33:03",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:04:40",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:04:58",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:04:58",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:05:14",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:05:25",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:05:44",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:06:10",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:07:53",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:08:21",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:09:46",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:10:35",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:10:56",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:11:09",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:12:07",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.329
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:12:23",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:12:27",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:12:27",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:12:29",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:13:56",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:14:16",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2994,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:14:34",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 13:14:40",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2995,
                    45.3289
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 14:26:59",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 14:27:00",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 18:24:58",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2872,
                    45.3149
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-19 18:25:29",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2873,
                    45.3149
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-20 15:32:59",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-20 16:10:31",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-20 16:35:06",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 10:46:12",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2966,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 10:46:30",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:06",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:17",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:17",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:18",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:19",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:19",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:23",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:23",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:24",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:28",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:28",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:29",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:32",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:41",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:41",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:40:45",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:41:21",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:41:21",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:42:30",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:42:43",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3256
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "time": "2021-01-23 12:43:03",
                  "user_id": 374,
                  "device": ""
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    14.2967,
                    45.3255
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {
                  "period": "2020-09-10 13:08:33 2021-01-23 14:02:38"
                },
                "geometry": {
                  "type": "LineString",
                  "coordinates": [
                    [
                      14.2991,
                      45.3289
                    ],
                    [
                      14.2996,
                      45.3291
                    ],
                    [
                      14.3002,
                      45.3292
                    ],
                    [
                      14.3005,
                      45.3294
                    ],
                    [
                      14.3012,
                      45.3299
                    ],
                    [
                      14.3019,
                      45.3305
                    ],
                    [
                      14.3022,
                      45.3308
                    ],
                    [
                      14.3023,
                      45.3311
                    ],
                    [
                      14.3023,
                      45.3316
                    ],
                    [
                      14.3024,
                      45.3323
                    ],
                    [
                      14.3022,
                      45.3329
                    ],
                    [
                      14.3018,
                      45.3327
                    ],
                    [
                      14.3016,
                      45.3323
                    ],
                    [
                      14.3013,
                      45.3317
                    ],
                    [
                      14.301,
                      45.331
                    ],
                    [
                      14.3008,
                      45.3307
                    ],
                    [
                      14.3003,
                      45.3302
                    ],
                    [
                      14.3006,
                      45.3298
                    ],
                    [
                      14.3006,
                      45.3296
                    ],
                    [
                      14.3001,
                      45.3292
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2996,
                      45.3287
                    ],
                    [
                      14.2988,
                      45.3291
                    ],
                    [
                      14.299,
                      45.3291
                    ],
                    [
                      14.2996,
                      45.3284
                    ],
                    [
                      14.2992,
                      45.3286
                    ],
                    [
                      14.2991,
                      45.3287
                    ],
                    [
                      14.2995,
                      45.3291
                    ],
                    [
                      14.2989,
                      45.3289
                    ],
                    [
                      14.2993,
                      45.3289
                    ],
                    [
                      14.2991,
                      45.3288
                    ],
                    [
                      14.2987,
                      45.3279
                    ],
                    [
                      14.2994,
                      45.3276
                    ],
                    [
                      14.2996,
                      45.3273
                    ],
                    [
                      14.2993,
                      45.3266
                    ],
                    [
                      14.2991,
                      45.3258
                    ],
                    [
                      14.2993,
                      45.325
                    ],
                    [
                      14.2989,
                      45.3235
                    ],
                    [
                      14.2984,
                      45.3249
                    ],
                    [
                      14.298,
                      45.3266
                    ],
                    [
                      14.2999,
                      45.333
                    ],
                    [
                      14.3048,
                      45.3372
                    ],
                    [
                      14.3074,
                      45.3409
                    ],
                    [
                      14.3118,
                      45.3442
                    ],
                    [
                      14.3172,
                      45.3498
                    ],
                    [
                      14.3188,
                      45.3529
                    ],
                    [
                      14.3237,
                      45.3567
                    ],
                    [
                      14.3291,
                      45.358
                    ],
                    [
                      14.3323,
                      45.3579
                    ],
                    [
                      14.3378,
                      45.3547
                    ],
                    [
                      14.3484,
                      45.3491
                    ],
                    [
                      14.3538,
                      45.3461
                    ],
                    [
                      14.3649,
                      45.3427
                    ],
                    [
                      14.3753,
                      45.341
                    ],
                    [
                      14.3797,
                      45.34
                    ],
                    [
                      14.3842,
                      45.3394
                    ],
                    [
                      14.3888,
                      45.3398
                    ],
                    [
                      14.3997,
                      45.3387
                    ],
                    [
                      14.4068,
                      45.3381
                    ],
                    [
                      14.4087,
                      45.338
                    ],
                    [
                      14.4148,
                      45.3369
                    ],
                    [
                      14.4176,
                      45.3357
                    ],
                    [
                      14.4248,
                      45.3321
                    ],
                    [
                      14.4255,
                      45.3317
                    ],
                    [
                      14.43,
                      45.3306
                    ],
                    [
                      14.4322,
                      45.3303
                    ],
                    [
                      14.4389,
                      45.327
                    ],
                    [
                      14.4418,
                      45.3258
                    ],
                    [
                      14.4466,
                      45.3261
                    ],
                    [
                      14.4487,
                      45.3264
                    ],
                    [
                      14.449,
                      45.3247
                    ],
                    [
                      14.4514,
                      45.3236
                    ],
                    [
                      14.4699,
                      45.3172
                    ],
                    [
                      14.4677,
                      45.3184
                    ],
                    [
                      14.4663,
                      45.3189
                    ],
                    [
                      14.4658,
                      45.3191
                    ],
                    [
                      14.4651,
                      45.3194
                    ],
                    [
                      14.4648,
                      45.3197
                    ],
                    [
                      14.4649,
                      45.32
                    ],
                    [
                      14.4653,
                      45.3197
                    ],
                    [
                      14.4647,
                      45.3197
                    ],
                    [
                      14.465,
                      45.3194
                    ],
                    [
                      14.4658,
                      45.319
                    ],
                    [
                      14.4662,
                      45.3186
                    ],
                    [
                      14.467,
                      45.3186
                    ],
                    [
                      14.4709,
                      45.3174
                    ],
                    [
                      14.4711,
                      45.3208
                    ],
                    [
                      14.4731,
                      45.321
                    ],
                    [
                      14.4764,
                      45.3204
                    ],
                    [
                      14.4768,
                      45.3222
                    ],
                    [
                      14.4822,
                      45.3189
                    ],
                    [
                      14.4923,
                      45.3223
                    ],
                    [
                      14.4884,
                      45.3249
                    ],
                    [
                      14.4748,
                      45.3319
                    ],
                    [
                      14.4637,
                      45.3355
                    ],
                    [
                      14.4425,
                      45.3369
                    ],
                    [
                      14.4345,
                      45.3387
                    ],
                    [
                      14.4105,
                      45.3432
                    ],
                    [
                      14.3906,
                      45.347
                    ],
                    [
                      14.382,
                      45.3479
                    ],
                    [
                      14.3608,
                      45.3511
                    ],
                    [
                      14.3517,
                      45.3554
                    ],
                    [
                      14.3372,
                      45.3614
                    ],
                    [
                      14.3344,
                      45.3628
                    ],
                    [
                      14.3272,
                      45.361
                    ],
                    [
                      14.3253,
                      45.3605
                    ],
                    [
                      14.3273,
                      45.3608
                    ],
                    [
                      14.3285,
                      45.3587
                    ],
                    [
                      14.3236,
                      45.3568
                    ],
                    [
                      14.3213,
                      45.3556
                    ],
                    [
                      14.3179,
                      45.3522
                    ],
                    [
                      14.3139,
                      45.3457
                    ],
                    [
                      14.308,
                      45.3421
                    ],
                    [
                      14.3069,
                      45.34
                    ],
                    [
                      14.3022,
                      45.3355
                    ],
                    [
                      14.3027,
                      45.3321
                    ],
                    [
                      14.3014,
                      45.3299
                    ],
                    [
                      14.2993,
                      45.3253
                    ],
                    [
                      14.2991,
                      45.3244
                    ],
                    [
                      14.2993,
                      45.3248
                    ],
                    [
                      14.2993,
                      45.3251
                    ],
                    [
                      14.299,
                      45.3259
                    ],
                    [
                      14.2991,
                      45.3262
                    ],
                    [
                      14.2995,
                      45.327
                    ],
                    [
                      14.2991,
                      45.3277
                    ],
                    [
                      14.2985,
                      45.3279
                    ],
                    [
                      14.2987,
                      45.3284
                    ],
                    [
                      14.2998,
                      45.3289
                    ],
                    [
                      14.2992,
                      45.3288
                    ],
                    [
                      14.2995,
                      45.3291
                    ],
                    [
                      14.2992,
                      45.3289
                    ],
                    [
                      14.2997,
                      45.3285
                    ],
                    [
                      14.2994,
                      45.3283
                    ],
                    [
                      14.2989,
                      45.329
                    ],
                    [
                      14.2989,
                      45.3285
                    ],
                    [
                      14.2996,
                      45.3291
                    ],
                    [
                      14.2991,
                      45.3291
                    ],
                    [
                      14.4567,
                      45.5677
                    ],
                    [
                      14.4567,
                      45.5677
                    ],
                    [
                      14.4567,
                      45.5677
                    ],
                    [
                      14.4567,
                      45.5677
                    ],
                    [
                      14.4675,
                      45.5888
                    ],
                    [
                      14.4675,
                      45.5888
                    ],
                    [
                      14.4675,
                      45.5888
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3217
                    ],
                    [
                      14.3815,
                      45.3398
                    ],
                    [
                      14.2966,
                      45.3257
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.4675,
                      45.5888
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2993,
                      45.3288
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3288
                    ],
                    [
                      14.2994,
                      45.3288
                    ],
                    [
                      14.2994,
                      45.3288
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3288
                    ],
                    [
                      14.2994,
                      45.3288
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.2966,
                      45.3257
                    ],
                    [
                      14.2965,
                      45.326
                    ],
                    [
                      14.2964,
                      45.3264
                    ],
                    [
                      14.2963,
                      45.3265
                    ],
                    [
                      14.3277,
                      45.3431
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2966,
                      45.3257
                    ],
                    [
                      14.2966,
                      45.3257
                    ],
                    [
                      14.2966,
                      45.3257
                    ],
                    [
                      14.2966,
                      45.3257
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.4567,
                      45.5677
                    ],
                    [
                      14.3069,
                      45.3483
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.2966,
                      45.3217
                    ],
                    [
                      14.2973,
                      45.3262
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.4675,
                      45.5888
                    ],
                    [
                      14.2982,
                      45.3264
                    ],
                    [
                      14.3149,
                      45.3378
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.3533,
                      45.3554
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.329
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2994,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2995,
                      45.3289
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2872,
                      45.3149
                    ],
                    [
                      14.2873,
                      45.3149
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.2966,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3255
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3256
                    ],
                    [
                      14.2967,
                      45.3255
                    ]
                  ]
                }
              }
            ]
          }
        }
        json.data.features.forEach((feature)=> {
          if (feature.geometry.type==="Point"){
            const popup = new mapboxgl.Popup({offset: 25}).setHTML('<div>' + feature.properties.time +'<br><hr>'+feature.properties.device + '</div>');
            // add marker to map
            new mapboxgl.Marker({draggable: false,color:`rgb(255, 0, 255)`})
                .setLngLat(feature.geometry.coordinates).setPopup(popup)
                .addTo(map);
          }
          if (feature.geometry.type==="LineString"){
            // add track to map

            map.addSource('track', {'type': 'geojson', 'data': feature});
            map.addLayer({
              'id': 'track',
              'type': 'line',
              'source': 'track',
              'layout': {
                'line-join': 'round',
                'line-cap': 'round'
              },
              'paint': {
                'line-color': '#3A4CA6',
                'line-width': 8
              }
            });
          }
        })
        map.addSource('points',json);
        map.addLayer({
          'id': 'user-track',
          'type': 'symbol',
          'source': 'points',
        });



        // map.loadImage( 'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
        //     function (error, image) {
        //       if (error)
        //         throw error;
        //       map.addImage('custom-marker', image);
        //       // Add a GeoJSON source
        //       map.addSource('points',{
        //         "type": "geojson",
        //         "data": {
        //           "type": "FeatureCollection",
        //           "features": [
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:28:16",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:28:16"
        //               },
        //               "geometry": {
        //                 "type": "LineString",
        //                 "coordinates": [
        //                   null,
        //                   [
        //                     37.6176,
        //                     55.7558
        //                   ]
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:30:17",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:43:08",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:43:24",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:43:58",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:44:28",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:45:20",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:45:22",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:46:12",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   14.2966,
        //                   45.3256
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:46:30",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   14.2967,
        //                   45.3255
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:47:07",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:47:07",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 10:47:11",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 11:01:50",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 11:04:15",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 11:05:12",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 11:06:42",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 11:07:56",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 11:08:22",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 11:08:24",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 11:08:24",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 11:09:47",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 11:18:22",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 11:19:48",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 11:21:13",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             },
        //             {
        //               "type": "Feature",
        //               "properties": {
        //                 "time": "2021-01-23 11:22:07",
        //                 "user_id": 374,
        //                 "device": ""
        //               },
        //               "geometry": {
        //                 "type": "Point",
        //                 "coordinates": [
        //                   37.6176,
        //                   55.7558
        //                 ]
        //               }
        //             }
        //           ]
        //         }
        //       })
        //       // Add a symbol layer
        //       map.addLayer({
        //         'id': 'symbols',
        //         'type': 'symbol',
        //         'source': 'points',
        //         'layout': {
        //           'icon-image': 'custom-marker'
        //         }
        //       });
        //     }
        //   );


        // const marker = new mapboxgl.Marker({draggable: true})
        //     .setLngLat([14.2873, 45.3148])
        //     .setPopup(new mapboxgl.Popup({ offset: 25 })
        //         .setHTML('<div>Rona Central Reception</div>'))
        //     .addTo(map);
        // marker.on('dragstart', onDragStart);
        // marker.on('dragend', onDragEnd);

        apartments.value.forEach((aparment) => {
          const popup = new mapboxgl.Popup({offset: 25}).setHTML('<div id="' + aparment.id + '">' + aparment.name + '<br><hr>' + aparment.address + '</div>');
          const appMarker = new mapboxgl.Marker({draggable: false}).setLngLat([aparment.longitude, aparment.latitude]).setPopup(popup).addTo(map);
          popup.on('open', function(ev) {
            lat.value = ev.target.getLngLat().lat;
            lon.value = ev.target.getLngLat().lng;
            addressFromMap.value = aparment.address
            choosedApartmentId.value = aparment.id
            map.flyTo({
              center:  [lon.value,lat.value], zoom: 15
            });
          });
          popup.on('close', function(ev) {
            lat.value = ev.target.getLngLat().lat;
            lon.value = ev.target.getLngLat().lng;
            addressFromMap.value = aparment.address
            choosedApartmentId.value = aparment.id
            map.flyTo({
              center:  [14.2873, 45.3148], zoom: 9
            });
          });
        })

        resources.value.forEach((worker) => {
          const popup = new mapboxgl.Popup({ offset: 25 }).setHTML('<div id="worker-'+worker.id+'">'+worker.name+ '<br><hr>'+worker.sleepingPlace+'<br>500 min</div>');
          const coordinates = (worker.sleepingPoint !== undefined)?worker.sleepingPoint.coordinates:[14.2873, 45.3148];
          const workerMarker = new mapboxgl.Marker({draggable: false,color:`rgb(255, 0, 0)`}).setLngLat(coordinates).setPopup(popup).addTo(map);
        })
      });
    });
    return { title,lon, lat, addressFromMap, apartments, resources, choosedApartmentId};
  },
  // components : {
  //   LMap,
  //   LGeoJson,
  // },
  // async beforeMount() {
  //   // HERE is where to load Leaflet components!
  //   const { circleMarker } = await import("leaflet/dist/leaflet-src.esm");
  //
  //   // And now the Leaflet circleMarker function can be used by the options:
  //   this.geojsonOptions.pointToLayer = (feature, latLng) =>
  //       circleMarker(latLng, { radius: 8 });
  //   this.mapIsReady = true;
  // },
};
</script>

<style>
#map {
  height: 100vh;
}
#mapContainer {
  width: 80vw;
  height: 100vh;
}
</style>